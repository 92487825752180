<template>
  <div>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title>
          <back-button color="grey" @click="dialog = false" />
          <h1 class="title">{{ title }}</h1>
        </v-card-title>
        <v-card-text>
          <v-row justify="center" align="start">
            <v-col cols="12" class="text-center">
              <property-value
                property="Data"
                :value="timeForm.date | formatDate"
              />
              <div class="d-flex">
                <property-value property="Paciente" :value="namePatient" />
                <span
                  v-if="patient.name && canEdit"
                  class="ml-2"
                  color="red"
                  style="font-size: small; cursor: pointer; color: red"
                  @click="resetPatient"
                >
                  (Remover paciente)
                </span>
                <span
                  v-if="!patient.name && !showPatientForm"
                  class="ml-2"
                  color="red"
                  style="font-size: small; cursor: pointer; font-weight: 600"
                  @click="showPatientForm = true"
                >
                  <v-icon style="font-size: small; cursor: pointer">
                    add_circle
                  </v-icon>
                  Adicionar paciente
                </span>
              </div>

              <patient-form
                v-if="showPatientForm"
                @submit="handlePatientSubmit"
                @cancel="showPatientForm = false"
              />

              <template v-if="isCancelled">
                <property-value
                  property="Motivo cancelamento"
                  :value="timeForm.cancellationReason"
                />
                <property-value
                  property="Origem do cancelamento"
                  :value="timeForm.cancellationSource | roleToString"
                />
              </template>
            </v-col>

            <template v-if="showPatientForm === false">
              <v-col lg="5" cols="12">
                <time-picker
                  :disabled="!canEdit"
                  :value="timeForm.startAt"
                  label="Início"
                  :error-message="areTimesValid"
                  @input="(value) => changeProperty(value, 'startAt')"
                />
              </v-col>
              <v-col lg="5" cols="12">
                <time-picker
                  :disabled="!canEdit"
                  :value="timeForm.endAt"
                  label="Fim"
                  :error-message="areTimesValid"
                  @input="(value) => changeProperty(value, 'endAt')"
                />
              </v-col>
              <v-col v-if="isStatusNoPatient && !patient.name" cols="12">
                <div class="d-flex justify-center">
                  <v-switch
                    :disabled="!canEdit"
                    :input-value="timeForm.isPublic"
                    label="Anunciar publicamente?"
                    :false-value="false"
                    :true-value="true"
                    @change="(value) => changeProperty(value, 'isPublic')"
                  />
                </div>
              </v-col>
            </template>
          </v-row>
          <template v-if="showPatientForm === false">
            <v-row justify="center" class="text-center">
              <v-col cols="12" sm="8">
                <par-button v-if="canEdit" block @click="addTime">
                  <v-icon class="mr-2">save</v-icon>
                  Salvar
                </par-button>
                <par-button
                  v-if="hasPatient"
                  block
                  class="mt-3"
                  @click="openDetails"
                >
                  <v-icon class="mr-2">info</v-icon>
                  Ver detalhes
                </par-button>
                <par-button
                  v-if="canDeleteAppointment"
                  class="mt-3"
                  color="error"
                  outlined
                  block
                  @click="remove"
                >
                  <v-icon class="mr-1">warning</v-icon>
                  <span>Apagar</span>
                </par-button>
                <par-button
                  v-if="canCancel"
                  class="mt-3"
                  color="error"
                  block
                  @click="openCancellation"
                >
                  <v-icon class="mr-1">warning</v-icon>
                  <span>Cancelar agendamento</span>
                </par-button>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <detail-dialog ref="detailDialog" />
    <cancellation-dialog ref="cancellationDialog" @submit="changeEventStatus" />
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "FormTimeAppointment",
  components: {
    DetailDialog: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/DetailDialog"
      ),
    CancellationDialog: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/CancellationDialog"
      ),
    PatientForm: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/PatientForm"
      ),
  },
  data: () => ({
    showPatientForm: false,
    patient: {},
  }),

  computed: {
    dialog: {
      get() {
        return this.$store.state.appointments.therapist.dialogTime;
      },
      set(value) {
        this.$store.commit("appointments/therapist/setDialogTime", value);
      },
    },
    timeForm() {
      return this.$store.state.appointments.therapist.timeForm;
    },
    title() {
      return `${this.timeForm.id ? "Editar" : "Criar"} agendamento`;
    },
    areTimesValid() {
      const { startAt, endAt, date } =
        this.$store.state.appointments.therapist.timeForm;
      const formattedStartAt = dayjs(`${date} ${startAt}`, "YYYY-MM-DD HH:mm");
      const formattedEndAt = dayjs(`${date} ${endAt}`, "YYYY-MM-DD HH:mm");
      return formattedStartAt.isAfter(formattedEndAt)
        ? "Horário início não pode ser maior que horário fim"
        : "";
    },
    isStatusNoPatient() {
      return this.timeForm.status === 0;
    },
    hasPatient() {
      return !this.isStatusNoPatient;
    },
    isCancelled() {
      return this.timeForm.status === 4;
    },
    canDeleteAppointment() {
      return true;
      //  return this.timeForm.canDelete;
    },
    canEdit() {
      return this.timeForm.canEdit === undefined ? true : this.timeForm.canEdit;
    },
    canCancel() {
      return this.timeForm.canCancel;
    },

    namePatient() {
      const namePatient = this.patient.name || this.timeForm.patientName;
      if (namePatient !== "Sem paciente") {
        return namePatient;
      } else {
        return null;
      }
    },
  },

  mounted() {
    this.patient = { name: this.timeForm.patientName };
  },

  methods: {
    async addTime() {
      if (this.areTimesValid) {
        this.$toasted.global.error({ message: this.areTimesValid });
        return;
      }
      await this.$store.dispatch("appointments/therapist/handleEventToAdd");
    },
    changeProperty(value, property) {
      this.$store.commit("appointments/therapist/setPropertyTimeForm", {
        value,
        property,
      });
    },
    remove() {
      this.$store.dispatch("appointments/therapist/deleteAppointment");
    },
    openDetails() {
      this.$refs.detailDialog.open(this.timeForm.id);
    },
    openCancellation() {
      this.$refs.cancellationDialog.open({
        appointmentId: this.timeForm.id,
        source: "therapist",
      });
    },
    changeEventStatus({ appointmentId, newStatus, source, reason }) {
      this.$store.commit(
        "appointments/therapist/changeAppointmentStatusToCancelled",
        {
          appointmentId,
          newStatus,
          source,
          reason,
        }
      );
    },
    handlePatientSubmit(patient) {
      this.showPatientForm = false;
      this.patient = patient;
      // add patient to timeForm

      this.$store.commit("appointments/therapist/setPatient", patient);
    },
    resetPatient() {
      this.patient = {};
      this.$store.commit("appointments/therapist/setPatient", null);
    },
  },
};
</script>

<style scoped></style>
